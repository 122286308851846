import * as React from 'react';
import {
    Create,
    DateInput,
    SimpleForm,
    TextInput,
    useTranslate,
    PasswordInput,
    email,
    CreateProps,
    useNotify,
    useRedirect,
    useGetList,
    ArrayInput,
    SimpleFormIterator,
    AutocompleteArrayInput,
    AutocompleteInput,
    useCreateSuggestionContext,
    Title,
    required,
    NumberInput,
} from 'react-admin';
import {
    Box,
    Chip,
    Stack,
    Typography,
    Divider,
    Tooltip,
    IconButton,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { createApiKey, PackageMetadataSpec, Module } from '../dataProvider';
import { useNavigate } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';

const FunctionCreate = () => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const translate = useTranslate();

    const { data: packages = [] } = useGetList<{ id: string }>('packages', {});
    const packageMap: Record<string, PackageMetadataSpec> = packages.reduce(
        (acc, item: any) => {
            // @ts-ignore
            acc[item.name] = item;
            return acc;
        },
        {}
    );

    const [topics, setTopics] = useState<{ id: string }[]>([]);

    const CreateTopics = () => {
        const { filter, onCreate } = useCreateSuggestionContext();
        useEffect(() => {
            if (filter && !topics.some(choice => choice.id === filter)) {
                const newOption = { id: filter, name: filter };
                setTopics(prevChoices => [...prevChoices, newOption]);
                onCreate(newOption);
            }
        }, [filter, onCreate, topics, setTopics]);
        return null;
    };

    const [currentPackage, setCurrentPackage] = useState<PackageMetadataSpec>();
    const [currentModuleList, setCurrentModuleList] = useState<Module[]>([]);
    const [currentModule, setCurrentModule] = useState<Module>();

    return (
        <>
            <Create>
                <SimpleForm sx={{ maxWidth: 500 }} defaultValues={{}}>
                    <SectionTitle label="resources.functions.fieldGroups.section" />
                    <TextInput
                        name="name"
                        source="name"
                        isRequired
                        validate={required()}
                    />
                    <AutocompleteInput
                        name="package"
                        source="package"
                        isRequired={true}
                        choices={packages}
                        validate={required()}
                        onChange={(p: string) => {
                            setCurrentPackage(packageMap[p]);
                            setCurrentModuleList(
                                Object.entries(packageMap[p].modules ?? {}).map(
                                    ([key, module]) => ({
                                        ...module,
                                        name: key,
                                        id: key,
                                    })
                                )
                            );
                        }}
                    />
                    <AutocompleteInput
                        name="module"
                        source="module"
                        isRequired={true}
                        choices={currentModuleList}
                        validate={required()}
                        onChange={(m: string) =>
                            setCurrentModule(
                                currentPackage?.modules?.[m] ?? undefined
                            )
                        }
                    />
                    <AutocompleteArrayInput
                        name="sources"
                        source="sources"
                        choices={topics}
                        create={<CreateTopics />}
                    />
                    <TextInput name="sink" source="sink" />
                    {currentModule && currentModule.config && (
                        <Box sx={{ width: '100%' }}>
                            <Divider sx={{ marginY: 2 }} />
                            {Object.entries(currentModule.config).map(
                                ([key, configItem]) => {
                                    const InputComponent =
                                        configItem.type === 'string'
                                            ? TextInput
                                            : NumberInput;

                                    return (
                                        <Box
                                            key={key}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            sx={{ width: '100%' }}
                                        >
                                            <InputComponent
                                                source={`config.${key}`}
                                                label={configItem.displayName}
                                                isRequired={
                                                    configItem.required ?? false
                                                }
                                                validate={
                                                    configItem.required
                                                        ? required()
                                                        : undefined
                                                }
                                                sx={{ flexGrow: 1 }}
                                            />
                                            <Tooltip
                                                title={`${configItem.doc}`}
                                            >
                                                <IconButton
                                                    sx={{
                                                        marginLeft: 1,
                                                        mb: 2,
                                                    }}
                                                >
                                                    <InfoIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    );
                                }
                            )}
                        </Box>
                    )}
                </SimpleForm>
            </Create>
        </>
    );
};

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label as string)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

export default FunctionCreate;
